import React, { Component } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import './contact.css.less'
import { Container, Grid, Dropdown } from 'semantic-ui-react'
import contactBg1 from '../images/prof-cptable.png'
import mslantGlass from '../images/mag-slant-white.png'
import axios from 'axios'
import { CREDITS_API_URL } from 'gatsby-env-variables'

const organization_type = [
	{
		key: 'School',
		text: 'School',
		value: 'school'
	},
	{
		key: 'Law Firm',
		text: 'Law Firm',
		value: 'law firm'
	},
	{
		key: 'Business',
		text: 'Business',
		value: 'business'
	},
	{
		key: 'Government Agency',
		text: 'Government Agency',
		value: 'government agency'
	}
]
class Contact extends Component {
	state = {
		first_name: '',
		last_name: '',
		email: '',
		organization: '',
		org_type: '',
		job_title: '',
		mobile_no: '',
		no_of_access: '',
		needs: ''
	}

	handleChange = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleSelect = (event, data) => {
		this.setState({
			org_type: data.value
		})
	}

	handleSubmit = event => {
		event.preventDefault()

		const contact = {
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			email: this.state.email,
			organization: this.state.organization,
			org_type: this.state.org_type,
			job_title: this.state.job_title,
			mobile_no: this.state.mobile_no,
			no_of_access: this.state.no_of_access,
			needs: this.state.needs
		}

		axios
			.post(`${CREDITS_API_URL}`, { contact })
			.then(res => {
				alert(res.data.message)
				// console.log(res.data)
			})
			.catch(error => {
				alert('Something went wrong')
				// console.log(error.response)
			})
	}

	render() {
		return (
			<Layout>
				<Seo title="Contact" />
				<Grid.Column className="contact-container">
					<div className="color-overlay"></div>
					<Container>
						<img
							src={contactBg1}
							alt=""
							className="bg-img1"
							style={{ top: '41px' }}
						/>
						<Grid stackable centered columns={16}>
							<Grid.Row className="contact-content">
								<Grid.Column width={6}>
									<div className="contact-title">
										<h2>
											<b>
												We'd love <br /> to hear <br />
												from you
											</b>
										</h2>
									</div>
								</Grid.Column>
								<Grid.Column width={10}>
									<form onSubmit={this.handleSubmit} className="ui form">
										<div className="inline fields">
											<div className="four wide field">
												<label htmlFor="first_name" style={{ color: 'white' }}>
													First Name
												</label>
											</div>
											<div className="thirteen wide field">
												<input
													type="text"
													placeholder="First Name"
													name="first_name"
													id="first_name"
													minLength="3"
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label htmlFor="last_name" style={{ color: 'white' }}>
													Last Name
												</label>
											</div>
											<div className="thirteen wide field">
												<input
													type="text"
													placeholder="Last Name"
													name="last_name"
													id="last_name"
													minLength="3"
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label
													htmlFor="organization"
													style={{ color: 'white' }}
												>
													Organization
												</label>
											</div>
											<div className="thirteen wide field">
												<input
													type="text"
													placeholder="Organization"
													name="organization"
													id="organization"
													minLength="3"
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label htmlFor="org_type" style={{ color: 'white' }}>
													Organization Type
												</label>
											</div>
											<div className="thirteen wide field">
												<Dropdown
													placeholder="Select Organization"
													selection
													name="org_type"
													id="org_type"
													options={organization_type}
													style={{ width: '100%' }}
													onChange={this.handleSelect}
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label htmlFor="job_title" style={{ color: 'white' }}>
													Job Title
												</label>
											</div>
											<div className="thirteen wide field">
												<input
													type="text"
													placeholder="Job Title"
													name="job_title"
													id="job_title"
													minLength="3"
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label htmlFor="mobile_no" style={{ color: 'white' }}>
													Phone
												</label>
											</div>
											<div className="thirteen wide field">
												<input
													type="tel"
													placeholder="Mobile Number(0906XXXXXXX)"
													name="mobile_no"
													pattern="[0-9]{11}"
													id="mobile_no"
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label htmlFor="email" style={{ color: 'white' }}>
													Email
												</label>
											</div>
											<div className="thirteen wide field">
												<input
													type="email"
													placeholder="Email"
													name="email"
													id="email"
													onChange={this.handleChange}
													required
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label
													htmlFor="no_of_access"
													style={{ color: 'white' }}
												>
													Number of Users <br /> who Need Access
												</label>
											</div>
											<div className="thirteen wide field">
												<input
													type="number"
													name="no_of_access"
													id="no_of_access"
													placeholder="Number of Users who Need Access"
													onChange={this.handleChange}
												/>
											</div>
										</div>
										<div className="inline fields">
											<div className="four wide field">
												<label htmlFor="needs" style={{ color: 'white' }}>
													Describe your needs
												</label>
											</div>
											<div className="thirteen wide field">
												<textarea
													rows="4"
													placeholder="Describe your needs"
													name="needs"
													id="needs"
													minLengt="10"
													onChange={this.handleChange}
													required
												></textarea>
											</div>
										</div>
										<div className="field" style={{ textAlign: 'center' }}>
											<button
												className="ui button primary"
												type="submit"
												name="submit"
												id="submit"
											>
												Submit
											</button>
										</div>
									</form>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
					<img src={mslantGlass} alt="" className="contact-mslantGlass" />
				</Grid.Column>
			</Layout>
		)
	}
}

export default Contact
